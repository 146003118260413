export default [
    {
        text: "Intro Video",
        link: "https://www.youtube.com/watch?v=-ncvem5JAVo&ab_channel=WorldofIo",
    },
    {
        text: "Playlist",
        link: "https://www.youtube.com/playlist?list=PLjMDIlamRholkSH_W2YgZvAXgQ4M1MO__",
    },
];
